import { windowLoad } from 'app/shared/utils';

const {
  ASSET_HOSTNAME: assetHostname,
  SENTRY_DSN: dsn,
  SENTRY_OPTIONS,
  location: { href: locationHref, search: locationSearch },
} = window;
const {
  error, info, log, warn,
} = console;
const allowed = [locationHref, `${assetHostname}/`];

const debugParam = 'debug';
const urlParams = new URLSearchParams(locationSearch);
const reqIdleCallback = window.requestIdleCallback;

let debugMode = false;
let debugNames = [];
let scheduled = false;
let captureException = false;
let withScope = false;
// allow the test to do a fake dependency injection
const proxy = args => args;

// performance enhancement for loading sentry module of the time
const shouldLoadSentry =
  // do not server to crawlers
  !/(gle|ing)bot/.test(navigator.userAgent) &&
  // do not serve to slower connections
  (window.navigator.connection ? window.navigator.connection.effectiveType !== '4g' : true) &&
  // 1% sample on allowed UAs / connections
  Math.random() <= 0.01;

// If a debug param exists, use its value or default to true
// E.g: ?debug or ?debug=logger,otherlogger
/* istanbul ignore if */
if (urlParams.has(debugParam)) {
  const debugValue = urlParams.get(debugParam);
  if (debugValue.length) {
    debugNames = debugValue.toUpperCase().split(',');
  } else {
    debugMode = true;
  }
}

/**
 * Loads sentry dynamically
 */
function loadSentry() {
  import('@sentry/browser')
    .then(proxy)
    .then(({ init, captureException: capture, withScope: scope }) => {
      init({
        dsn,
        beforeSend(event) {
          const requestUrl = event?.request?.url || '';
          if (!allowed.includes(requestUrl)) return null;
          return event;
        },
        maxBreadcrumbs: 0,
        ignoreErrors: [
          // Random plugins/extensions
          'top.GLOBALS',
          // Facebook borked
          'fb_xd_fragment',
          // Facebook timeout
          'Load timeout for modules: facebook',
          // Piano ie/edge error
          "Can't execute code from a freed script",
        ],
        ...SENTRY_OPTIONS,
      });
      // update variables
      captureException = capture;
      withScope = scope;
    });
}

/**
 * Checks the debugMode, or if this instance name is included
 * @return {boolean}  Whether logs should be visible or not
 */
function isDebugMode(name) {
  if (debugMode) return debugMode;
  if (debugNames.length) return debugNames.includes(name);
  return debugMode;
}

/**
 * Logger defines a shared logger for use within various modules
 *
 * Usage:
 *     This string will be appended to console output - "NAME HERE: Foo"
 *     this.logger = new Logger( "Name Here" );
 *
 *     this.logger.error( "Foo" ); // Outputs with the console.error
 *     this.logger.info( "Foo" ); // Outputs with the console.info
 *     this.logger.log( "Foo" ); // Outputs with the console.log
 *     this.logger.warn( "Foo" ); // Outputs with the console.warn
 *
 * This module allows for string substitution as well
 *     this.logger = new Logger( "Name Here" );
 *
 *     Outputs "Output 1, 2, 3, 4"
 *     this.logger.log( "Output %d, %d, %d, %d", 1, 2, 3, 4 )
 */
class Logger {
  constructor(name) {
    const loggerName = typeof name === 'string' ? name.toUpperCase() : 'LOGGER';
    this.prefix = `${loggerName}: `;
    this.debugMode = isDebugMode(loggerName);

    // if sentry hasn't been loaded, load it once
    if (shouldLoadSentry && dsn && !scheduled) {
      // where supported, this will execute when the main thread is idle
      if (reqIdleCallback) {
        scheduled = reqIdleCallback(loadSentry);
      } else {
        scheduled = true;
        windowLoad(loadSentry);
      }
    }
  }

  log() {
    if (this.debugMode) log(this.prefix, ...arguments);
  }

  info() {
    if (this.debugMode) info(this.prefix, ...arguments);
  }

  warn() {
    warn(this.prefix, ...arguments);
  }

  error() {
    error(this.prefix, ...arguments);
    // if sentry has been loaded/setup, send the error
    if (withScope) {
      // set the local scope for the next exception
      // this allows us to have a reusable single sentry instance
      withScope((scope) => {
        if (arguments[1] && typeof arguments[1] === 'object') {
          scope.setExtras(arguments[1]);
        }
        // this call needs to be last here so scopes are reset after
        captureException(new Error(this.prefix + arguments[0]));
      });
    }
  }
}

export default Logger;
