import Logger from 'app/shared/logger';
import { importScripts } from 'app/shared/utils';

const fetchInit = {
  method: 'GET',
  credentials: 'omit',
  mode: 'cors',
  cache: 'default',
};

// TODO Script version could change often, abstract to configurable
const requiredJs = window.location.protocol + '//res.wx.qq.com/open/js/jweixin-1.4.0.js';

const assetsPath = {
  'ELLE China': 'ellechina',
  'ELLE Men': 'ellemen',
};

/**
 * wechat defines the functionality to load WeChat share
 * This module is a jQuery free adaptation of the originak Js file provided by WeChat.
 */
class WeChat {
  constructor() {
    if (this.constructor.isMicroMessenger()) {
      // Initialize variables
      this.initVariables();
      importScripts(requiredJs).then(this.getWxSign.bind(this));
    }
  }

  /**
   * Test WeChat APP user agent
   *
   */
  static isMicroMessenger() {
    return navigator.userAgent.includes('MicroMessenger');
  }

  /**
   * Initialize variables
   */
  initVariables() {
    const protocol = window.location.protocol;
    const hostName = window.location.hostname;
    const iconPath = '/assets/images/apple-touch-icon.png';
    const siteName = window.HRST.site.name;

    const signdomain = `${protocol}//cp.hearst.com.cn`;
    const timestamp = Math.round(new Date().getTime() / 1000).toString();
    /* eslint-disable camelcase */
    const wx_nonceStr = window.crypto.getRandomValues(new Uint32Array(1))[0];
    this.wx_appid = window.weChatAppId;
    this.wx_url = window.location.href.split('#')[0];

    const site_logo = `${protocol}//${hostName}/sites/${assetsPath[siteName]}${iconPath}`;

    this.wx_appsecret = '';
    this.wx_title = document.querySelectorAll("meta[name='title']")[0].content;

    const contentThumbnail = document.querySelectorAll("meta[name='thumbnail']")[0];
    this.wx_thumb = contentThumbnail ? contentThumbnail.content : site_logo;
    this.wx_desc = document.querySelectorAll("meta[name='description']")[0].content;

    this.signendpoint = `${signdomain}/tool/getwxsign?appid=${this.wx_appid}&appsecret=${
      this.wx_appsecret
    }&noncestr=${wx_nonceStr}&timestamp=${timestamp}&url=${encodeURIComponent(this.wx_url)}`;

    // set up logger
    this.logger = new Logger('WeChat');
  }
  /**
   * getWxSign attempts to get a valid app We chat login
   */
  getWxSign() {
    return fetch(this.signendpoint, fetchInit)
      .then(this.handleErrors)
      .then(response => response.json())
      .then(data => this.handleCheckAccessSuccess(data))
      .catch(err => this.handleCheckAccessFail(err));
  }

  /**
   * If valid getwxsign signature set up config for
   * wx object
   *
   * @param {object} data Response object
   */
  handleCheckAccessSuccess(data) {
    if (data) {
      let wx = window.wx || {};
      wx.config({
        debug: false,
        appId: this.wx_appid,
        timestamp: data.timestamp,
        nonceStr: data.noncestr,
        signature: data.signature,
        jsApiList: ['onMenuShareTimeline', 'onMenuShareAppMessage'],
      });
      /* eslint-disable func-names */
      wx.ready(
        function () {
          wx.onMenuShareTimeline({
            title: this.wx_title,
            link: this.wx_url,
            imgUrl: this.wx_thumb,
            cancel() {},
          });
          wx.onMenuShareAppMessage({
            title: this.wx_title,
            link: this.wx_url,
            imgUrl: this.wx_thumb,
            desc: this.wx_desc,
            type: '',
            dataUrl: '',
          });
        }.bind(this),
      );
    }
  }

  /**
   * Log a failed access getwxsign ajax request
   * @param {object} err Error object
   */
  handleCheckAccessFail(error) {
    this.logger.warn('Check Access Failure: ', error);
  }

  /**
   * Catch network error for fetch
   * @return {object} response object
   */
  handleErrors(response) {
    const { ok } = response;
    if (!ok) {
      this.logger.warn('Fetch Error: ', response);
      return false;
    }
    return response;
  }
}

export default new WeChat();
