// noop returns empty object
const noop = () => ({});

// Set the touch status of the current device
const touchEnabled = Modernizr.touchevents;

/**
 * returns document.readyState
 * helpful for testing
 */
const getDocumentReadyState = () => document.readyState;

/**
 * Remove a listener from the specified event.
 * @param {Event} event
 * @param {function(Event):void} listener
 */
const removeListener = (event, listener) => {
  event.target.removeEventListener(event.type, listener);
};

/**
 * Checks a key event against an array of key names return the value
 * USE CASE: listen for escape key up on different OS where the name may be different
 *
 * `event.keyCode` deprecated, use of `event.key` recommended
 * @link https://developer.mozilla.org/en-US/docs/Web/API/KeyboardEvent/key
 *
 * @param {Event} event
 * @param {KeysArray} keysArray
 */
const isKeyupEvent = (event, keysArray) => event && event.key && keysArray.includes(event.key);

/**
 * Add a listener to for the specified event types.
 * @param {Element} element
 * @param {(string|string[])} eventType
 * @param {function(Event):void} listener
 * @param {object} options object optional
 * @return {object} Returns an object with method utilities
 */
const addListener = (element, eventType, listener, options = false) => {
  if (!element) {
    return noop;
  }

  const eventTypes = Array.isArray(eventType) ? eventType : [eventType];
  const elements =
    element instanceof window.NodeList || Array.isArray(element) ? element : [element];
  const cleanup = [];

  elements.forEach((target) => {
    eventTypes.forEach((type) => {
      // switch click to touchstart on touch devices
      type = type === 'click' && touchEnabled ? 'touchstart' : type;
      target.addEventListener(type, listener, options);
      cleanup.push({
        target,
        type,
        listener,
      });
    });
  });

  return {
    remove: () => cleanup.forEach(evt => removeListener(evt, evt.listener)),
  };
};

/**
 * errorCheckCallback checks that a callback function is valid,
 * otherwise defines an empty function and returns it
 */
const errorCheckCallback = callback =>
  (callback === undefined || typeof callback !== 'function' ? noop : callback);

/**
 * Executes a function on DOMContentLoaded
 */
const domLoad = (callback) => {
  // Check callback
  callback = errorCheckCallback(callback);
  return getDocumentReadyState() !== 'loading'
    ? callback()
    : document.addEventListener('DOMContentLoaded', callback);
};

/**
 * ensureInstanceOf ensures that an input object is an instance
 * of an input constructor. if so, the original object is return.
 * if not, a new instance of the constructor is returned
 *
 * NOTE: If the contructor needs to be passed arguments, they can
 * be bound to the constructor function when it's passed.
 * Ex:
 * c = Utils.ensureInstanceOf( c, C.bind( C, { option: "foo" } ) )
 */
const ensureInstanceOf = (object, Constructor) =>
  (object instanceof Constructor ? object : new Constructor());

/**
 * formUrlWithParams takes a URL and an object of params and forms
 * a valid URL with them
 */
const formUrlWithParams = (url, params) => {
  // Form params with fallback
  params = params === undefined || typeof params !== 'object' ? {} : params;

  // Check if there are params to append
  if (!Object.keys(params).length) {
    return url;
  }

  // Check for any existing params
  url += url.indexOf('?') !== -1 ? '&' : '?';

  Object.keys(params).forEach((key) => {
    const value = params[key];
    // If the param value is "null", treat as a boolean param
    url += key + (value === null || typeof value === 'undefined' ? '' : '=' + value) + '&';
  });

  // Remove trailing ampersands
  return url.replace(/&$/, '');
};

/**
 * Gets a cookie value given the name of the cookie.
 */
const getCookieByName = (key) => {
  let returnItem = false;
  let name = key + '=';
  let ca = document.cookie.split(';');
  let i;
  let c;
  for (i = 0; i < ca.length; i += 1) {
    c = ca[i];

    while (c.charAt(0) === ' ') {
      c = c.substring(1);
    }

    if (c.indexOf(name) === 0) {
      returnItem = c.substring(name.length, c.length);
    }
  }
  return returnItem;
};

const setCookie = (cookieName, cookieValue, extraDays) => {
  const d = new Date();
  d.setTime(d.getTime() + (extraDays * 24 * 60 * 60 * 1000));
  let expires = 'expires=' + d.toUTCString();
  document.cookie = cookieName + '=' + cookieValue + ';' + expires + ';path=/';
};

/**
 * forms a cookie string
 */
const getCookieString = (key, item) => key + '=' + item + '; expires=0; path=/';

/**
 * Attempts to retrieve a key from a source object who's value matches
 * the specified input. If the key couldn't be found, returns false or
 * a specified fallback value.
 *
 * NOTE: This method will return the key of the first instance found for the given
 * value. That means that, for an object containing duplicate values (think booleans),
 * this method will not provide full accuracy
 *
 * @param  {mixed} value        Value to search for
 * @param  {Object} source      Source object to search
 * @param  {mixed} fallback     Value to be returned if key could not be found
 *
 * @return {mixed}              Returns the key if found, otherwise the specified fallback
 */
const getKey = (value, source, fallback) => {
  // Loop variable
  let key = fallback || false;

  // Loop through object properties
  Object.keys(source || {}).forEach((prop) => {
    const val = source[prop];
    if (val === value) {
      key = prop;
    }
  });

  // Return fallback
  return key;
};

/**
 * Getter for MutationObserver
 *
 * @return {function} MutationObserver constructor
 */
const getMutationObserver = () => MutationObserver;

/**
 * Takes a URL and returns an object of the query parameters
 */
const getQueryParamObj = (url) => {
  let vars;
  let params = {};
  let i;
  let pair;

  if (url && url !== '') {
    vars = url.substr(url.indexOf('?') + 1).split('&');

    for (i = 0; i < vars.length; i += 1) {
      pair = vars[i].split('=');

      params[pair[0]] = pair[1];
    }

    return params;
  }

  return {};
};

/**
 * returns window.location.search
 */
const getWindowLocationSearch = () => window.location.search;

/**
 * returns window.sessionStorage
 */
const getSessionStorage = () => window.sessionStorage;

/**
 * Retrieves the current page hash, without the hash symbol.
 *
 * @return {string} hash value of current URL
 */
const getUrlHash = () => window.location.hash.replace('#', '');

/**
 * Attempts to retrieve a value from a source object. If
 * the value couldn't be found, returns false or a specified
 * fallback value.
 *
 * @param  {string} key      Key of value to be retrieved. Can also be
 *                           specified using dot notation to retrieve
 *                           nested values. Ex: "content.title"
 * @param  {Object} source   Source object.
 * @param  {mixed}  fallback Value to be returned if key could not be
 *                           retrieved.
 * @return {mixed}           Value of the key, false, or specified fallback
 */
const getValue = (key, source, fallback) => {
  let parts;

  // use provided default or false
  fallback = typeof fallback === 'undefined' ? false : fallback;

  // if key or source are empty, return fallback
  if (!key || !Object.keys(source || {}).length) {
    return fallback;
  }
  // get the key parts
  parts = key.split('.');

  // shift the first key off the front
  key = parts.shift();

  // if the source doesn't contain the key or value is undefined, return the fallback
  if (!Object.prototype.hasOwnProperty.call(source, key) || typeof source[key] === 'undefined') {
    return fallback;
  }

  // if there are left over key parts, recurse. otherwise return the value
  return parts.length ? getValue(parts.join('.'), source[key], fallback) : source[key];
};

/**
 * Returns a promise to allow loading scripts asynchronously
 * @param {string} url Script to load
 */
const importer = url =>
  new Promise((resolve, reject) => {
    let script = document.createElement('script');
    script.type = 'text/javascript';
    script.src = url;
    script.addEventListener('load', () => resolve(script), false);
    script.addEventListener('error', () => reject(script), false);
    document.body.appendChild(script);
  });

/**
 * Wraps the importer into a helper exported function
 * to allow passing both a string or an array
 * @param {mixed} urls A string or array of scripts to load
 */
const importScripts = (urls) => {
  urls = urls || [];
  const scripts = typeof urls === 'string' ? [urls] : urls;
  if (!scripts.length) return Promise.reject();
  return scripts.length > 1 ? Promise.all(urls.map(importer)) : importer(scripts[0]);
};

/**
 * Whether this is an Arb request, i.e. utm_campaign begins with 'arb'
 * @param {Location} location Location of the current page from window.location
 * @return {bool}
 */
const isArb = (location) => {
  const urlParams = new URLSearchParams(location.search);
  const campaign = urlParams.get('utm_campaign') || getCookieByName('gpt_src');
  return campaign !== null && /^arb/i.test(campaign);
};

/**
 * handles loading a standard app (waiting for document ready and then `new App()`)
 * NOTE: This function proxies to the internal `domLoad` function. Applications
 * needing more specific dom-ready functionality should use the `domLoad` function
 * directly and provide their own callbacks
 */
const loadApp = App => domLoad(() => new App());

/**
 * Returns the next sibling of a given element matching a given selector.
 * @param {Element} el - Element whose siblings are being searched.
 * @param {String} selector - Selector for which to search.
 * @return {Element|null} Returns the next occurence of the selector or null.
 */
const nextElement = (el, selector) => {
  do {
    el = el.nextElementSibling;
  } while (el instanceof Element && !el.matches(selector));

  return el;
};

/**
 * Accepts a path and will prepend the current language prefix.
 *
 * @param  {string} path             String to prepend prefix to
 * @param  {string} excludedLanguage String containing language to exclude.
 *
 * @return {string}      String containing language prefix
 */
const prependLanguagePrefix = (path, excludedLanguage) => {
  const languagePrefix = getValue('URL_LANGUAGE_PREFIX', window);
  // Check if path contains leading slash
  if (path.charAt(0) !== '/') {
    path = '/' + path;
  }
  // Check for prefix
  if (!languagePrefix) {
    return path;
  }
  // Check for exclusions
  if (excludedLanguage && excludedLanguage === languagePrefix) {
    return path;
  }
  // Use slice to check if the first letters of the path are equal to the
  // language prefix
  if (path.slice(1, languagePrefix.length + 2) === languagePrefix + '/') {
    return path;
  }

  return '/' + languagePrefix + path;
};

/**
 * Returns the next preceding sibling of a given element matching a given selector.
 * @param {Element} el - Element whose siblings are being searched.
 * @param {String} selector - Selector for which to search.
 * @return {Element|null} Returns the next occurence of the selector or null.
 */
const prevElement = (el, selector) => {
  do {
    el = el.previousElementSibling;
  } while (el instanceof Element && !el.matches(selector));

  return el;
};

/**
 * preventEventActions is used to prevent event actions from performing
 * or propagating
 */
const preventEventActions = (e) => {
  // Prevent default if possible
  if (e && e.preventDefault) {
    e.preventDefault();
  }

  // Stop propagation if possible
  if (e && e.stopPropagation) {
    e.stopPropagation();
  }

  return e;
};

/**
 * Wrap requestAnimationFrame in a promise, accepts a callback that is executed
 * in the rAF and receives the promise resolve and reject functions - if these
 * are not called then the promise will resolve with the return value of the
 * callback.
 * @param {*} callback
 */
const rafPromise = callback =>
  new Promise((resolve, reject) =>
    requestAnimationFrame(() => {
      resolve(callback(resolve, reject));
    }),
  );

/**
 * HTML5 Session Storage utils with cookies for back up
 */
const sessionStoreDelete = (key) => {
  let sessionStorage = getSessionStorage();

  // if sessionStorage is present, use that
  if (sessionStorage) {
    // easy object property API
    sessionStorage.removeItem(key);
  } else {
    // deleting the back up cookie
    document.cookie = key + '=; expires=Thu, 01 Jan 1970 00:00:01 GMT;';
  }
};

/**
 * HTML5 Session Storage utils with cookies for back up
 */
const sessionStoreGet = (key) => {
  let returnItem = false;
  let sessionStorage = getSessionStorage();

  // if sessionStorage is present, use that
  if (sessionStorage) {
    // use sessionStorage
    returnItem = sessionStorage.getItem(key);
  } else {
    // without sessionStorage we'll have to use a session scoped cookie
    returnItem = getCookieByName(key);
  }

  return returnItem;
};

/**
 * stringify turns an element into a JSON-encoded string,
 * so long as it's not already a string
 */
const stringify = el => (typeof el === 'string' ? el : JSON.stringify(el));

/**
 * HTML5 Session Storage utils with cookies for back up
 */
const sessionStorePut = (key, item) => {
  // if sessionStorage is present, use that
  let sessionStorage = getSessionStorage();

  // stringify items that are objects
  if (typeof item !== 'string') {
    item = stringify(item);
  }

  if (sessionStorage) {
    // use sessionStorage
    sessionStorage.setItem(key, item);
  } else {
    // without sessionStorage we'll have to use a session scoped cookie
    document.cookie = getCookieString(key, item);
  }
};

/**
 * Creates and starts up a Mutation Observer for a given DOM element
 * Executes a specified callback function based on config parameters
 *
 * Also executes a specified callback function based on config
 * parameters which indicate what counts as a mutation.
 *
 * @param  {Object} el       DOM element that should be observed for mutations.
 * @param  {string} callback Function name to be executed when a mutation occurs.
 * @param  {Object} config   Optional key value pairs of configuration options.
 *
 * @return {Object}          MutationObserver object, so we can disconnect if desired.
 */
const setMutationObserver = (el, callback, config) => {
  let MutationObserver = getMutationObserver();

  // create an observer instance
  let observer = new MutationObserver(function cb(mutations) {
    mutations.forEach(callback);
  });

  // default configuration, if none passed in
  config = config || { attributes: true, childList: true, subtree: true };

  // observe body content for changes
  observer.observe(el, config);

  // return MutationObserver so it can be disconnected
  return observer;
};

/**
 * windowLoad is used to call a callback upon either the DOM already being loaded,
 * or upon window load
 */
const windowLoad = (callback) => {
  // Check callback
  callback = errorCheckCallback(callback);

  return getDocumentReadyState() === 'complete'
    ? callback()
    : window.addEventListener('load', callback);
};

/**
 * setTimeout/setInterval helpers using rAF
 * @param {function} callback Callback method
 * @param {string} delay The time to wait
 * @param {boolean} interval If this timeout should repeat
 */
/* istanbul ignore next */
const requestTimeout = (callback, delay, interval) => {
  let start = Date.now();
  let myReq;

  function step() {
    let current = Date.now();
    let delta = current - start;

    if (delta >= delay) {
      const stopInterval = callback();
      if (interval && !stopInterval) {
        start = new Date().getTime();
        myReq = requestAnimationFrame(step);
      }
    } else {
      myReq = requestAnimationFrame(step);
    }
  }
  myReq = requestAnimationFrame(step);
  return myReq;
};

/**
 * Append an elements children into a container.
 *
 * @param {object} feed - HTMLElement object containing items
 */
const appendToContainer = (elementWithItems, container) => {
  const fragment = document.createDocumentFragment();
  const nodes = [...elementWithItems.childNodes];

  // add first-loaded-item class on first new loaded item
  nodes.find(c => c.classList !== undefined).classList.add('first-loaded-item');

  // convert live list to static
  nodes.forEach(node => fragment.appendChild(node));

  return rafPromise(() => container.appendChild(fragment));
};

/**
 * Remove .first-loaded-item class
 */
const removeFirstLoadedItemClass = () => {
  // remove class if exist
  const firstLoadedElement = document.querySelector('.first-loaded-item');
  if (firstLoadedElement) firstLoadedElement.classList.remove('first-loaded-item');
};

/**
 * Parse cookies once from the document
 */
const parsedCookies = (function parseCookies(cookies) {
  if (!cookies.length) return {};
  const output = {};
  cookies.forEach((c) => {
    if (!c) return;
    const [key, ...v] = c.split('=');
    output[key] = decodeURIComponent(v.join('='));
  });
  return output;
}(document.cookie.split(/; */)));

/**
 * Get object value for given key or undefined if it is not exists
 *
 * @param {object} obj - source to get the value from
 * @param {string} key - key for value. It may contain "." to access nested values
 */
const getObjectValue = (obj, key) => key.split('.').reduce((result, k) => (result ? result[k] : undefined), obj);

/**
 * Get first existing value from given list of keys
 *
 * @param {object} obj - source
 * @param {string[]} keys - list of object keys
 */
const getFirstMatched = (obj, keys) => keys.map(key => getObjectValue(obj, key)).filter(v => v)[0];

/**
 * Obtaining the retailer name form the product object
 *
 * @param {object} product
 * @returns {string}
 */
const getRetailerName = (product) => {
  /**
   * Obtaining the retailer name is based on best available non-empty field
   * fields are ordered by priority
   */
  let name = getFirstMatched(product, [
    'retailer.retailer_display_name',
    'custom_retailer',
    'vendor',
    'retailer.retailer_name',
    'retailer.display_name',
    'retailer.name',
  ]);
  if (name) {
    return name;
  }

  /* last resort - extract the domain from url */
  let url = getFirstMatched(product, ['retailer.url', 'retailer_url']);
  if (url) {
    try {
      url = new URL(url.replace('www.', ''));
      return url.host || '';
    } catch {
      // Invalid url
    }
  }
  return '';
};

/**
 * Check if URL is a Magento product
 *
 * @param {string} url
 * @returns {boolean}
 */
const isMagentoProduct = url => new RegExp('^https?://(' + window.magentoProductDomains.join('|') + ')').test(url);

export {
  isKeyupEvent,
  addListener,
  appendToContainer,
  domLoad,
  ensureInstanceOf,
  errorCheckCallback,
  formUrlWithParams,
  getCookieByName,
  getCookieString,
  getDocumentReadyState,
  getKey,
  getMutationObserver,
  getObjectValue,
  getQueryParamObj,
  getRetailerName,
  getSessionStorage,
  getUrlHash,
  getValue,
  getWindowLocationSearch,
  importScripts,
  isArb,
  isMagentoProduct,
  loadApp,
  nextElement,
  noop,
  prevElement,
  prependLanguagePrefix,
  preventEventActions,
  rafPromise,
  requestTimeout,
  removeListener,
  sessionStoreDelete,
  sessionStoreGet,
  sessionStorePut,
  setCookie,
  setMutationObserver,
  stringify,
  touchEnabled,
  windowLoad,
  removeFirstLoadedItemClass,
  parsedCookies,
};
